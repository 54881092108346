import React from 'react';
import { Configurator } from '@uc-common/configurator';
import { Container as Wrapper } from '@uc-common/container';
import styled, { css } from 'styled-components';

import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Navbar, NavbarModesEnum } from '../../components/Navbar';
import { FinalCTA } from '../../components/FinalCTA';
import { BigHeading, SectionSubtext } from '../../components/Typography';
import { frontmatter as originalFrontmatter } from '../widget/configure';

const Container = styled(Wrapper)(
  ({ theme: { tablet } }) => css`
    margin-top: 60px;
    margin-bottom: 100px;

    ${tablet()} {
      margin-top: 75px;
      margin-bottom: 80px;
    }
  `
);

const SectionTitle = styled.div(
  ({ theme: { tablet, desktop } }) => css`
    text-align: center;
    margin-bottom: 20px;

    ${tablet()} {
      margin-bottom: 40px;
    }

    ${desktop()} {
      margin-bottom: 60px;
    }
  `
);

const StyledSectionSubtext = styled(SectionSubtext)`
  margin-top: 20px;
`;

export const frontmatter = {
  canonicalUrl: 'https://uploadcare.com/widget/configure/',
  noindex: true,
};

const Configure = ({ location }) => (
  <CommonLayout
    meta={{
      ...originalFrontmatter,
      ...frontmatter,
    }}
    pathName={location.pathname}
  >
    <Navbar mode={NavbarModesEnum.PPC} />
    <Container>
      <SectionTitle>
        <BigHeading>Hey! I&apos;m the best uploader on the web</BigHeading>
        <StyledSectionSubtext>
          Adjust the Uploader look and configure its features live.
        </StyledSectionSubtext>
      </SectionTitle>

      <Configurator />
    </Container>
    <FinalCTA heading="Get your API keys" ppc />
  </CommonLayout>
);

export default Configure;
