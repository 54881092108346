import React from 'react';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import Image from '@uc-common/image';

import {
  FinalCTAHeading,
  FinalCTAContainer,
  FinalCTAButtonsWrapper,
  FinalCTALink,
  FinalCTACentredContainer,
  FinalCTAImageContainer,
} from './styles/FinalCTA.styles';

import { Link } from '../Link';

export const FinalCTA = ({
  className,
  heading = 'Ready to try Uploadcare?',
  buttonLabel = 'Get started for free',
  linkLabel = 'Questions? Talk to an expert',
  ppc = false,
  btnSignUpProps,
  btnScheduleDemoProps,
}) => (
  <FinalCTAContainer className={className}>
    <FinalCTACentredContainer>
      <FinalCTAHeading as="h2">{heading}</FinalCTAHeading>
      <FinalCTAButtonsWrapper>
        <Button
          as={Link}
          to="/accounts/signup/"
          type={ButtonTypeEnum.DARK}
          long
          {...btnSignUpProps}
        >
          {buttonLabel}
        </Button>
        {!ppc && (
          <FinalCTALink to="/schedule-demo/" {...btnScheduleDemoProps}>
            {linkLabel}
          </FinalCTALink>
        )}
      </FinalCTAButtonsWrapper>
      <FinalCTAImageContainer>
        <Image
          uuid="027a6c35-c20f-426b-8ec4-741d2e89ca8f"
          options={{ quality: 'smart', format: 'auto', crop: '100px50p' }}
          alt="Screenshot of usage statistics on the Uploadcare dashboard"
        />
      </FinalCTAImageContainer>
    </FinalCTACentredContainer>
  </FinalCTAContainer>
);
