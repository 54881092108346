import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { BigHeading, LinkWithArrow } from '../../Typography';

export const FinalCTAHeading = styled(BigHeading)(
  ({ theme: { tablet, laptop } }) => css`
    text-align: center;
    margin-bottom: 40px;

    ${tablet()} {
      margin-bottom: 30px;
    }
    ${laptop()} {
      max-width: 920px;
    }
  `
);

export const FinalCTAContainer = styled.article`
  width: 100%;
  background-color: #f6f7f8;
  overflow: hidden;
`;

export const FinalCTACentredContainer = styled(Container)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${tablet()} {
      padding-top: 80px;
    }

    ${laptop()} {
      padding-top: 100px;
    }

    ${desktop()} {
      padding-top: 120px;
    }
  `
);

export const FinalCTAButtonsWrapper = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    ${tablet()} {
      flex-direction: row;
      margin-bottom: 50px;
    }
  `
);

export const FinalCTALink = styled(LinkWithArrow)(
  ({ theme: { tablet, laptop } }) => css`
    color: #596570;
    margin: 30px 0 0 0;

    &:hover {
      color: #3771ff;
    }

    ${tablet()} {
      margin: 0 0 0 25px;
    }

    ${laptop()} {
      font-size: 15px;
    }
  `
);

export const FinalCTAImageContainer = styled.div(
  ({ theme: { tablet } }) => css`
    width: 100%;
    position: relative;
    top: 10px;

    img {
      width: 100%;
      box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.16),
        0 5px 20px rgba(0, 0, 0, 0.08);
      border-radius: 6px 8px 0 0;

      ${tablet()} {
        border-radius: 16px 16px 0 0;
      }
    }
  `
);
